.nav {
  background: #333333;
  font-family: "League Spartan", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.15) 0.95px 1.95px 1.6px;
}

.nav .navbar-brand {
  font-weight: 500;
}

.nav .navbar-collapse {
  flex-grow: 0;
}

.nav .navbar-nav {
  gap: 16px;
}


.nav .nav-link {
  /* line-height: 1.2; */
  display: block;
  height: 100%;
}

.nav .nav-link:hover {
  color: #ffff;
}

.nav .nav-link.active {
  font-weight: 500;
  position: relative;
}

.nav .nav-link.active::before {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% + 16px);
  height: 67px;
  top: -20px;
  left: -8px;
  background-color: #eaeae8;
  z-index: -1;

}

.nav .nav-item {
  font-size: 18px;

}

.nav li a {
  /* padding: 0 !important; */
  color: #fff;
}

.nav .nav-item .nav-link {
  padding: 0 !important;
}

.logo {
  /* width: clamp(70px, 3.125rem + 5.333vw, 112px); */
  width: 50px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav .nav-item .btn {
  font-size: 16px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}



.white-btn-outlined {
  outline: none;
  color: #fff !important;
  border: 2px solid #fff !important;
  padding: 6px 12px 4px 12px !important;
}

.white-btn-outlined:hover {
  background-color: #eaeae8 !important;
  color: #333333 !important;

}

.user-info__tooltip {
  visibility: hidden;
  width: 100px;
  background-color: #616161e5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -38px;
  left: -38px;
  opacity: 1;
  transition: opacity 0.3s;
  filter: drop-shadow(none) !important;
}

.user-info__tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  transform: rotate(180deg);
}

.user-info:hover .user-info__tooltip {
  visibility: visible;
  opacity: 1;
}

.bell-icon::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff6636;
  border: 2px solid white;
  top: 0;
  left: inherit;
  right: 2px;
}

.notification-menu {
  transform: translate3d(-80px, 7px, 0px);
}

@media screen and (max-width: 1200px) {
  .nav .nav-link.active::before {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    top: -1px;
    left: -4px;
    background-color: #eaeae8;
    z-index: -1;

  }
}

@media screen and (min-width: 992px) {


  .notification-menu {
    transform: translate3d(-65px, 8px, 0px);
  }
}

@media screen and (min-width: 1400px) {
  .nav .navbar-nav {
    gap: 32px;
  }

}

@media screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    background-color: #333333;
  }
  .dropdown .dropdown-menu {
    display: none;
  }
  .dropdown:hover .dropdown-toggle::after {
    border-top: 0;
    border-bottom: 0.3em solid;
  }
}

@media screen and (max-width: 991px) {
  .dropdown-toggle.show::after {
    border-top: 0;
    border-bottom: 0.3em solid;
  }
}

.navbar-toggler-icon {
  filter: invert(1);
}
