.tab-container {
    font-size: 15px;
    padding: 0px;
    list-style: none;
    background: #fff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 50px;
    position: relative;

}

.tab-container button {
    text-decoration: none;
    color: #777;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition-duration: 0.6s;
}

.tab-container button.active {
    color: #fff;
    /* height: 100%;
    width: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    border-radius: 50px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    border-radius: 50px;
    background: #3b1150;
    /* background: -moz-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
    background: -webkit-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
    background: linear-gradient(45deg, #05abe0 0%, #8200f4 100%); */
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05abe0', endColorstr='#8200f4', GradientType=1); */
}

.tab-container .selector {}

.date {
    position: fixed;
    border-left: 4px solid #607274;
    border-radius: 2px;
    background-color: #F5E8DD;
    bottom: 18px;
    left: 35px;
    box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}