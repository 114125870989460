/* --------------------- Layout ------------------------ */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

body,
html {
  margin: 0;
  padding: 0;

}


* {
  box-sizing: border-box;
  /* font-family: "Roboto Slab", serif; */
  font-family: "League Spartan", sans-serif;

}
.bg-light-gray{
  background-color: #e8e8e8;
}

.login-layout {
  min-height: 94vh;
}

.main-container {
  padding-top: 14px;
}

.dropdown-toggle::after {
  content: "";
  display: none !important;
}

input:focus {
  box-shadow: none !important;
}

/* input {
  outline: none !important;
} */

.Toastify__toast-container--top-right {
  top: 4em !important;
}

/* --------------------- Font ------------------------ */
.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 12px;
}

.font-md {
  font-size: 14px;
}

.font-normal {
  font-size: 16px;
}

.font-lg {
  font-size: 18px !important;
}

.text-blue {
  color: #00407a !important;
}

.text-gray-600 {
  color: #6e7485;
}

/* --------------------- Button ------------------------ */
.btn {
  line-height: 1.4 !important;
}

.blue-btn {
  background-color: #00509d !important;
  color: white !important;
}

.blue-btn:hover {
  /* background-color: #034888 !important; */
  filter: drop-shadow(0px 4px 4px rgba(0, 80, 157, 0.5));
  transition: all 0.3s ease;
}

.blue-btn:is(:active, :focus, :focus-visible) {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.button-secondary {
  background-color: #3b1150;
  color: white;
  outline: none;
  font-size: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}


img.button__image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

/* --------------------- logo ------------------------ */
.login__logo {
  width: clamp(200px, 5.125rem + 10.333vw, 250px);
}

:is(.login__logo, .logo) img {
  width: 100%;
  object-fit: cover;
}

/* --------------------- general ------------------------ */
.form-control:is(:active, :focus, :focus-visible) {
  box-shadow: none !important;
}

textarea:is(:focus, :focus-visible) {
  box-shadow: none !important;
}
.Toastify__toast--default{
  background-color: rgb(228, 217, 217) !important;
  color: black !important;
}
.loading{
  padding: 0;
  margin: 0;
  font-size: 18px;
}

.system-navbar {
  height: 56px;
}

.display-none {
  display: none !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.pdding-5 {
  padding: 5px !important;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-30 {
  padding-right: 30px !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}