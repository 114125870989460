.pick-list__header h1{
    color: #432a70;
    letter-spacing: 1px;
}
.pick-list__upload-input{
    width: 220px !important;
    border: 1px solid #6e92b5 !important;
}
.pick-list__file-name{
    font-size: 24px;
    color: #111519;
}
.line-select{
    border: 2px solid #6e92b5 !important;
    font-size: 17px !important;
}
.pick-list__component{
    gap: 180px;
}
.prod__input.form-control{
    border: 1px solid #9ca2a7  !important;
}
textarea[name=item-details]{
    resize: none !important;
}
