.chassis-hold-btn,
 .chassis-unhold-btn{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0;
  padding-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  /* background: #00407a; */
  position: relative;
}

.chassis-hold-btn{
  background: #00407a;
}

.chassis-unhold-btn{
  background: #448508;
}

.chassis-hold-btn--tooltip,
.chassis-unhold-btn--tooltip {
  /* content: 'Add To Chassis on hold'; */
  position: absolute;
  top: -20px;
  left: -49px;
  display: block;
  width: 120px;
  height: 20px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  background: #6e748563;
  border-radius: 4px;
  visibility: hidden;
}

.chassis-hold-btn:hover,
.chassis-unhold-btn:hover {
  /* background-color: #034888 !important; */
  filter: drop-shadow(0px 4px 4px rgba(0, 80, 157, 0.5));
  transition: all 0.3s ease;
}

.chassis-hold-btn--tooltip::before,
.chassis-unhold-btn--tooltip::before {
  content: " ";
  position: absolute;
  top: 100%;
  display: block;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #6e74859d transparent transparent transparent;
}

.chassis-hold-btn:hover .chassis-hold-btn--tooltip,
.chassis-unhold-btn:hover .chassis-unhold-btn--tooltip {
  visibility: visible;
}

.production-review-header {
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
}

.production-review-header select {
  font-size: 18px;
  height: 40px;
}

/* table {
    width: 100% !important;
} */
td:not(.no-border) {
  border-right: 1px solid #d0d0d0 !important;
  border-bottom: 1px solid #d0d0d0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.down-icon {
  height: 14px;
  width: 14px;
}

.expandable-row {
  transition: all 0.5s ease-in;
}

.expandable-row.expand {
  display: table-cell;
}

.expandable-row.close {
  display: none;
}

#mui-component-select-stationAlert {
  padding: 5px 32px 5px 5px;
}
.css-dsuxgy-MuiTableCell-root {
  padding: 4px 12px !important;
}
.css-1howxi1-MuiTableCell-root {
  padding: 6px 12px !important;
}

.station-name p{
  font-weight: 500;
}

.edit-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007bff; /* Blue color */
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
}

.edit-btn:hover {
  color: #0056b3; /* Darker blue on hover */
}

/* Optional: Add tooltip on hover */
.edit-btn::after {
  content: "Edit";
  position: absolute;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  transform: translateY(-25px);
}

.edit-btn:hover::after {
  opacity: 1;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between buttons */
  justify-content: center;
}

.assembly-row.highlighted {
  background-color: yellow !important;
  transition: background-color 0.10s ease-in-out;
}
