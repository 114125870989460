.table__head{
    color: #FFF;
    font-weight: 700;
    background: #9b4085 !important;
    white-space: nowrap;
}
.test{
    font-size: 100px !important;
}

.table__head_row{
    background-color: #3b1150;
    padding: 0;
}

.table__head_cell {
    color: #ffffff !important;
    border-left: 1px solid #d0d0d0;
    text-align: center !important;
    font-weight: bold !important;
}

.qa_model_field input{
    -webkit-text-fill-color: inherit !important;
}