.loader-wrapper{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #a6a6d270;
  z-index: 3;
}
.loader-spinner{
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 3;
  /* height: 100px; */
  /* width: 100px; */
  /* border-width: 8px;
  border-color: #fff #0056b3 #0056b3;
  border-style: solid;
  border-radius: 50%;
  animation: spinner infinite linear; */
}


.myGrid {
  font-family: "League Spartan", sans-serif;
}

.myGrid button,
.myGrid select {
  font-size: 18px;
}


.form-select {
  width: 128px !important;
  padding: 6px 12px 3px 12px !important;
}
.myGrid__search-box input {
  outline: none;
  width: clamp(180px, 8.125rem + 11.333vw, 250px);
  padding-left: 40px;
  padding-bottom: 4px;
  font-size: 14px;
  border: 2px solid #5c68b4;
}
@media screen and (min-width: 992px) {
  .myGrid__search-box input {
    width: 350px;
  }
}

.myGrid__search-box__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
}

.myGrid__search-box__icon svg {
  width: 20px;
  height: 20px;
}

.schedule-feed__data-grid {
  display: inline-block;
}

.rg-cell.rg-text-cell.valid[data-cell-colidx='1']::before {
  content: '\270E';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 10px;
  color: #004085;
}
.rg-cell.rg-text-cell.valid[data-cell-colidx='11'].tick::before {
  content: '\2714';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 10px;
  color: rgb(7, 146, 7);
}
.rg-cell.rg-text-cell.valid[data-cell-colidx='11'].cross::before {
  content: '\2716';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 10px;
  color: red;
}

.tab {
  border-bottom: 1px solid rgb(176 176 176);
}

/* Custom class for selected state */
.btn-selected {
  background-color: #0056b3;
  /* Darker shade of Bootstrap's primary blue */
  border-color: #004085;
  /* Even darker border for depth */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Add a shadow for a "pressed" effect */
}


.tab button {
  outline: none;
  border: none;
  font-size: 20px;
  background-color: #fff;
}

.tab button:is(:active, .active) {
  background-color: #00509d;
  color: white;
  border-radius: 5px 5px 0 0;
}

.schedule-feed__data-grid .rg-cell.rg-header-cell {
  background-color: #432a70 !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-wrap: wrap;
  padding: 10px 0px;
}

.reactgrid-content .rg-pane .rg-cell {
  font-size: 18px;
}

.reactgrid {
  position: static !important;
  font-family: "League Spartan", sans-serif;
  font-weight: 400;
}

.reactgrid-content .rg-pane.rg-pane-top {
  top: 135px;
}

.reactgrid-content .rg-pane-shadow.shadow-top {
  top: 65px;
}

.rg-cell {
  padding-left: 10px !important;
}

.reactgrid-content .rg-pane .rg-cell.rg-number-cell {
  justify-content: flex-start;
}

.rg-celleditor input {
  border: 0;
}

:is(.schedule__calender-grid, .schedule__data-grid)::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

:is(.schedule__calender-grid, .schedule__data-grid)::-webkit-scrollbar-track {
  background: #cfd8dc;
  border-radius: 5px;
}

:is(.schedule__calender-grid, .schedule__data-grid)::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 5px;
  border: 2px solid #cfd8dc;
}

.plus-icon {
  fill: #fff;
}

/* For Mobile Version */
.schedule__data--mobile {
  border-radius: 5px;
  border: 1px solid #b1c9e0;
  padding: 8px;
}

.schedule__data--mobile:is( :active,
  :hover,
  :visited,
  :focus,
  :focus-within,
  :focus-visible) {
  background-color: #b1c9e0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.schedule__data__field {
  overflow: hidden;
  white-space: wrap;
}

.rtl {
  direction: rtl;
}

.textarea--remarks {
  margin-bottom: 16px;
  resize: none;
}

textarea {
  font-size: 16px !important;
}

.assign__dropdown {
  border: 1px solid #3b1150 !important;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
  width: 200px;
}

.assign__dropdown input {
  padding-left: 40px;
  font-size: 12px;
}

.assign__search-box {
  outline: none;
  border-color: #3b1150 !important;
  border-style: solid;
  border-width: 0px 0px 1px 0px !important;
}

.assign__search-box__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
}

.assign__list-item:is(:hover, :focus, :active, :focus-visible, :focus-within) {
  background: #b1c9e0;
}

img.assign__image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.btn-danger {
  padding-bottom: 3.5px !important;
}

@media only screen and (max-width: 992px) {

  textarea.form-control {
    width: 80% !important;
  }
}