.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  font-size: 16px;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}